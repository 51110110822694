<!-- Copyright (©) 2021. Care Control Systems Limited. All rights reserved. -->


<template>
    <div class="container">
        <div
            v-for="(element, index) in resolvedValues"
            :key="index"
            class="day"
        >
            <header>
                {{ element.label }}
            </header>
            <Image
                :filePath="element.value ? 'tick.svg' : 'cross.svg'"
                :class="['icon', element.value ? 'blue' : 'red']"
                :alternativeText="element.value ? 'Yes' : 'No'"
            />
        </div>
    </div>
</template>


<script>

    // DEPENDENCIES

        // Components
        import Image from '@/components/generic/Image';

    //

    // COMPONENTS

        // Define components.
        const components = {
            Image
        };

    //

    // PROPS

        // Define props.
        const props = {
            values: {
                type: String
            }
        };

    //

    // DATA

        // Define data.
        const data = function () {
            return {
                days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
            };
        };

    //

    // COMPUTED

        // Resolve values.
        const resolvedValues = function () {

            // Split 'values'.
            const values = this.values.split(',');

            // Define array to store resolved values.
            const resolvedValues = [];

            // For each element of the 'values' array.
            for (const index in values) {

                // Construct object and push to array.
                resolvedValues.push({
                    label: this.days[index],
                    value: values[index] === '1' ? true : false
                });

            }

            // Return array.
            return resolvedValues;

        };

        // Define computed.
        const computed = {
            resolvedValues
        };

    //

    // EXPORT

        export default {
            name: 'WeekdayAvailability',
            components,
            props,
            data,
            computed
        };

    //

</script>


<style lang="scss" scoped>

    // DEPENDENCIES

        // Theme.
        @import '/src/styles/theme.scss';

    //

    // CLASSES

        .container {
            display: grid;
            border-radius: .5rem;
            overflow: hidden;
            margin: .5rem 0;
            @media screen and (min-width: $small) {
                grid-template-columns: repeat(7, 1fr);
            }
        }

        .day {
            display: grid;
            gap: .5rem;
            padding: 1rem 0;
            header {
                text-align: center;
            }
            &:nth-of-type(odd) {
                background-color: $colour-dark-trans;
            }
        }

        .icon {
            align-self: center;
            justify-self: center;
        }

    //

</style>
