<!-- Copyright (©) 2021. Care Control Systems Limited. All rights reserved. -->


<template>
    <div :class="['container', isOpen ? '' : 'closed']">
        <div class="content">
            <template v-if="isOpen">
                <div>
                    Applications are being accepted for this role!
                </div>
                <Button
                    label="Apply Now"
                    class="apply"
                    title="Apply for Job"
                    :action="showApplication"
                />
            </template>
            <template v-else>
                Applications Closed
            </template>
        </div>
    </div>
    <JobApplication
        :id="jobID"
        :siteURL="siteURL"
        ref="application"
    />
</template>


<script>

    // DEPENDENCIES

        // Components.
        import Button from '@/components/generic/Button';
        import JobApplication from '@/components/modules/JobApplication';

    //

    // COMPONENTS

        // Define components.
        const components = {
            Button,
            JobApplication
        };

    //

    // PROPS

        // Define props.
        const props = {
            isOpen: {
                type: Boolean,
                required: true
            },
            jobID: {
                type: String,
                required: true
            },
            siteURL: {
                type: String,
                required: true
            }
        };

    //

    // COMPUTED

        // Define computed.
        const computed = {
        };

    //

    // METHODS

        // Show application.
        const showApplication = function () {
            this.$refs.application.show();
        };

        // Define methods.
        const methods = {
            showApplication
        };

    // EXPORT

        export default {
            name: 'JobStatus',
            components,
            props,
            computed,
            methods
        };

    //

</script>


<style lang="scss" scoped>

    // DEPENDENCIES

        // Theme.
        @import '/src/styles/theme.scss';

    //

    // CLASSES

        .container {
            background-color: $colour-light-trans;
            margin: -1rem -.5rem 1.5rem;
            position: sticky;
            top: calc(#{$header-height} - 1px);
            padding: 1rem 1.25rem;
            font-weight: 600;
            font-size: 1rem;
            color: $colour-dark;
            border: $layout-border;
            border-left: none;
            border-right: none;
            backdrop-filter: blur(5px);
            z-index: 10;
            &.closed {
                background-color: $colour-warning;
                color: $colour-light;
            }
            
        }

        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            @media screen and (min-width: $large) {
                width: 80%;
                margin-left: auto;
                margin-right: auto;
                max-width: 1000px;
            }
        }

        .apply {
            margin-left: auto;
            white-space: nowrap;
        }

    //

</style>