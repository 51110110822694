<!-- Copyright (©) 2021. Care Control Systems Limited. All rights reserved. -->


<template>
    <Main>
        <Throbber v-if="isLoading" />
        <template v-if="!isLoading && jobDetails">
            <PageTitle :label="title" />
            <JobStatus
                :isOpen="true"
                :jobID="jobID"
                :siteURL="siteURL"
            />
            <div class="content">
                <section>
                    <h2>Overview</h2>
                    <Detail
                        filePath="provider.svg"
                        alternativeText="Provider"
                        :label="jobDetails.ProviderDetails"
                    />
                    <Detail
                        filePath="location.svg"
                        alternativeText="Location"
                        :label="jobDetails.LocationSummary"
                    />
                    <Detail
                        filePath="route.svg"
                        alternativeText="Location"
                        :label="UserDistance"
                    />
                    <Detail
                        filePath="money.svg"
                        alternativeText="Pay"
                        :label="jobDetails.PayDetails"
                    />
                    <Detail
                        filePath="clock.svg"
                        alternativeText="Hours per Week"
                        :label="jobDetails.NumberHours"
                    />
                    <Detail
                        filePath="posted.svg"
                        alternativeText="Posted"
                        :label="posted" 
                    />
                    <div v-if="jobDetails.NeedCar === 1">
                        <Detail 
                            filePath="car.svg"
                            alternativeText="Need Car"
                            :label="jobDetails.NeedCar === 1 ? 'Car Needed' : 'Car Not Needed'"
                        />
                    </div>
                </section>
                <section
                    v-if="jobDetails.FullJobDescription"
                    class="text right"
                >
                    <h2>Description</h2>
                    <div style="white-space: pre-line" v-html="GetJobDescription"></div>
                </section>
                <section v-if="benefits">
                    <h2>Benefits</h2>
                    <template
                        v-for="(element, index) in benefits"
                        :key="index"
                    >
                        <Detail
                            v-if="element.value"
                            :filePath="element.filePath"
                            :label="element.value"
                        />
                    </template>
                </section>
                <section v-if="requirements">
                    <h2>Requirements</h2>
                    <template
                        v-for="(element, index) in requirements"
                        :key="index"
                    >
                        <Detail
                            v-if="element.value"
                            :filePath="element.filePath"
                            :label="element.value"
                        />
                    </template>
                </section>
                <section
                    v-if="jobDetails.DaysNeed"
                    class="right"
                >
                    <h2>Days of Work</h2>
                    <WeekdayAvailability
                        :values="jobDetails.DaysNeed"
                    />
                </section>
                <section v-if="shifts">
                    <h2>Work Patterns</h2>
                    <Detail
                        v-for="(element, index) in shifts"
                        :key="index"
                        :filePath="element.value ? undefined : 'cross.svg'"
                        :label="element.label"
                        :alternativeText="element.value ? 'Yes' : 'No'"
                    />
                </section>
                <section
                    v-if="provider"
                    class="right"
                >
                    <h2>Provider</h2>
                    <template
                        v-for="(element, index) in provider"
                        :key="index"
                    >
                        <Detail
                            v-if="element.value"
                            :filePath="element.filePath"
                            :label="element.value"
                        />
                    </template>
                </section>
                <Button
                    v-if="isOpen"
                    title="Apply for Job"
                    class="apply"
                    :action="showApplication"
                >
                    <Image
                        filePath="apply.svg"
                        alternativeText="Apply"
                        class="icon"
                    />
                    Apply for Job
                </Button>
            </div>
            <JobApplication
                :id="jobID"
                :siteURL="siteURL"
                ref="application"
            />
        </template>
    </Main>
</template>


<script>

    // DEPENDENCIES

        // Components.
        import Main from '@/components/generic/Main';
        import PageTitle from '@/components/generic/PageTitle';
        import Throbber from '@/components/generic/Throbber';
        import Image from '@/components/generic/Image';
        import Button from '@/components/generic/Button';
        import Detail from '@/components/generic/Detail';
        import WeekdayAvailability from '@/components/generic/WeekdayAvailability';
        import JobApplication from '@/components/modules/JobApplication';
        import JobStatus from '@/components/modules/JobStatus';

        // Common.
        import { generateKey, hasValidProperties, updateTitle } from '@/common/Utilities';
        import { postRequest } from '@/common/API';

    //

    // COMPONENTS

        // Define components.
        const components = {
            Main,
            PageTitle,
            Throbber,
            Image,
            Button,
            Detail,
            WeekdayAvailability,
            JobApplication,
            JobStatus
        };

    //

    // DATA

        // Define data.
        const data = function () {
            return {
                isLoading: true,
                jobDetails: null
            };
        };

    //

    // COMPUTED

        // Job ID.
        const jobID = function () {
            return this.$route.query.id ? this.$route.query.id : null;
        };

        const UserDistance = function () {
            return this.$route.query.distance ? this.$route.query.distance : null;
        }

        // Site URL.
        const siteURL = function () {
            return this.jobDetails && this.jobDetails.SiteURL ? this.jobDetails.SiteURL : null;
        };

        // Is open.
        const isOpen = function () {
            return this.jobDetails && this.jobDetails.JobStatus === 'Open' ? true : false;
        };

        // Title.
        const title = function () {
            return this.jobDetails ? `${this.jobDetails.JobTitle} at ${this.jobDetails.ProviderDetails}` : null;
        };

        // Posted.
        const posted = function () {

            // If 'jobDetails' has been defined.
            if (this.jobDetails) {

                // Get posted date.
                const dateString = this.jobDetails.PostedDate.split('/');

                // Construct date object.
                const postedDate = new Date(dateString[2], dateString[1] - 1, dateString[0]);

                // Get current date.
                const currentDate = new Date();

                // Checks to see if the post was made today, if so return a slightly different label.
                if (currentDate.getDate() + '/' + currentDate.getMonth() + '/' + currentDate.getFullYear() 
                    === postedDate.getDate() + '/' + postedDate.getMonth() + '/' + postedDate.getFullYear()) {
                    return `Posted Today`
                }

                // Ascertain difference.
                const difference = Math.abs(currentDate - postedDate);

                // Convert difference to days.
                const daysDifference = Math.ceil(difference / (1000 * 60 * 60 * 24)); 

                // Return constructed string.
                return `Posted ${daysDifference} day${daysDifference > 1 ? 's' : ''} ago`;

            }

            // Else, return false.
            return false;

        };

        // Provider.
        const provider = function () {

            // If 'jobDetails' has been defined.
            if (this.jobDetails) {

                // Deconstruct job details.
                const { ProviderDetails, LocationSummary, IndustrySector } = this.jobDetails;

                // Construct provider object.
                const provider = {
                    ProviderDetails: {
                        value: ProviderDetails ? ProviderDetails : null,
                        filePath: 'provider.svg'
                    },
                    LocationAddress: {
                        value: LocationSummary ? LocationSummary : null,
                        filePath: 'location.svg'
                    },
                    IndustrySector: {
                        value: IndustrySector ? IndustrySector : null,
                        filePath: 'sector.svg'
                    }
                }

                // If values exist in at least one property.
                if (hasValidProperties(provider)) {

                    // Return benefits object.
                    return provider;
    
                }

            }

            // Else, return false.
            return false;

        };

        // Benefits.
        const benefits = function () {

            // If 'jobDetails' has been defined.
            if (this.jobDetails) {

                // Deconstruct job details.
                const { PaidBreaks, JoiningBonus, BonusScheme } = this.jobDetails; 

                // DEBUG 
                console.log(JoiningBonus)

                // Construct benefits object.
                const benefits = {
                    PaidBreaks: {
                        value: PaidBreaks === '1' ? 'Paid Breaks' : null
                    },
                    JoiningBonus: {
                        value: JoiningBonus ? `£${JoiningBonus} Joining Bonus` : null
                    },
                    BonusScheme: {
                        value: BonusScheme ? BonusScheme : null
                    }
                };

                // If values exist in at least one property.
                if (hasValidProperties(benefits)) {

                    // Return benefits object.
                    return benefits;
    
                }

            }

            // Else, return false.
            return false;

        };

        // Requirements.
        const requirements = function () {

            // If 'jobDetails' has been defined.
            if (this.jobDetails) {

                // Deconstruct job details.
                const {
                    ExperienceNeeded,
                    ExperienceDesired,
                    QualificationsNeeded,
                    QualificationsDesired
                } = this.jobDetails; 

                // Construct benefits object.
                const requirements = {
                    Experience: {
                        value: ExperienceNeeded !== undefined && ExperienceDesired !== undefined && (ExperienceNeeded !== 0 && ExperienceDesired !== 0) ? `Between ${ExperienceNeeded} to ${ExperienceDesired} years experience` : null,
                        filePath: 'experience.svg'
                    },
                    Qualifications: {
                        value: QualificationsNeeded && QualificationsDesired ? `${QualificationsNeeded} to ${QualificationsDesired}` : null,
                        filePath: 'education.svg'
                    }
                };

                // If values exist in at least one property.
                if (hasValidProperties(requirements)) {

                    // Return benefits object.
                    return requirements;
    
                }

            }

            // Else, return false.
            return false;

        };

        // Shifts.
        const shifts = function () {

            // Return formatted array.
            return [
                {
                    label: 'Work Evenings',
                    value: this.jobDetails.WorkEvenings
                },
                {
                    label: 'Work Nights',
                    value: this.jobDetails.WorkNights
                },
                {
                    label: 'Work Weekends',
                    value: this.jobDetails.WorkWeekends
                }
            ];

        };

        const GetJobDescription = function () {
            // Get message text from current message object.
            let jobDescriptionText = this.jobDetails.FullJobDescription;
            
            // Sanitise text.
            try {
                jobDescriptionText = decodeURIComponent(jobDescriptionText);
            } catch (error) {
                console.log(error);
            }

            // Return processed text.
            return jobDescriptionText
        };

        // Define computed.
        const computed = {
            jobID,
            siteURL,
            isOpen,
            title,
            posted,
            provider,
            benefits,
            requirements,
            shifts,
            GetJobDescription,
            UserDistance
        };

    //

    // METHODS

        // Get job listings.
        const getJobDetails = async function () {

            // Set 'isLoading' state to true.
            this.isLoading = true;

            // Define API request object.
            const request = {
                target: this.$store.getters.API.host,
                endPoint: this.$store.getters.API.path + "/HTML/getJobDetail.aspx",
                body: [
                    {
                        key: "JobDetailRequest",
                        value: {
                            JobDetailRequest: {
                                SecurityKey: generateKey(),
                                JobID: this.jobID ? this.jobID : null
                            }
                        }
                    }
                ]
            };

            // Make post request.
            const response = await postRequest(request);

            // If response successful.
            if (response && Object.prototype.hasOwnProperty.call(response, 'JobDetails')) {

                // Update 'jobs' state value.
                this.jobDetails = response.JobDetails;

            } else {

                // Go to 'resource-not-found' page.
                this.$router.replace({
                    name: 'resource-not-found'
                });

            }

            // Set 'isLoading' state to false.
            this.isLoading = false;

        };

        // Show application.
        const showApplication = function () {
            this.$refs.application.show();
        };

        const resolveEscapedString = function (string) {
            const newString = string;
            const test = [
            {
                regex: /(\\r)/g,
                replacement: '<br />'
            },
            {
                regex: /(\\n)/g,
                replacement: '<br />'
            },
            {
                regex: /(\\")/g,
                replacement: '"'
            }
            ]

            for (const index in test) {

                const element = test[index];

                console.log(element);

                newString.replaceAll(element.regex, element.replacement);
            }
            return newString
        }

        // Define methods.
        const methods = {
            getJobDetails,
            showApplication,
            resolveEscapedString
        };

    //

    // WATCH

        // Define watch.
        const watch = {
            title: function () {
                
                // If title has been defined.
                if (this.title) {

                    // Invoke the 'updateTitle' function.
                    updateTitle(this.title);

                }

            }
        };

    //

    // MOUNTED

        // Define mounted.
        const mounted = function () {

            // Invoke the 'getJobDetails' method.
            this.getJobDetails();

        };

    //

    // EXPORT

        export default {
            name: 'JobDetails',
            components,
            data,
            computed,
            methods,
            watch,
            mounted
        };

    //

</script>


<style lang="scss" scoped>

    // DEPENDENCIES

        // Theme.
        @import '/src/styles/theme.scss';

    //

    // ELEMENTS

        h2 {
            @extend .section-title;
        }

        section {
            @extend .standard-container;
        }

    //

    // CLASSES

        .content {
            @extend .content-center;
        }

        .text {
            color: $colour-dark;
        }

        .apply {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: .5rem;
            min-height: 4rem;
            font-size: 1.1rem;
            font-weight: 500;
            @media screen and (min-width: $large) {
                font-size: 1rem;
                padding: .5rem .75rem;
                min-height: 3.5rem;
            }
        }

    //

</style>
